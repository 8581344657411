import React, { useState } from "react";

/// React router dom
import { BrowserRouter, Router, Switch, Route, Redirect } from "react-router-dom";
import decode from 'jwt-decode';

/// Css
import "./index.css";
import "./chart.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Pages
import Login from "./pages/Login";
// import ForgotPassword from "./pages/ForgotPassword";
// import LockScreen from "./pages/LockScreen";
// import Error400 from "./pages/Error400";
// import Error403 from "./pages/Error403";
// import Error404 from "./pages/Error404";
// import Error500 from "./pages/Error500";
// import Error503 from "./pages/Error503";
/// Widget
// import Widget from "./pages/Widget";

/// Deshboard
import Home from "./components/Dashboard/Home";
import TematicaS from "./components/Dashboard/Campaign";
// import SocialNetworkCampaign from "./components/Dashboard/SocialNetworkCampaign";
// import Spendings from "./components/Dashboard/Spendings";
// import Analytics from "./components/Dashboard/Analytics";
// import NewCompaign from "./components/Dashboard/NewCompaign";

/// Bo
// import UiAlert from "./components/bootstrap/Alert";
// import UiAccordion from "./components/bootstrap/Accordion";
// import UiBadge from "./components/bootstrap/Badge";
// import UiButton from "./components/bootstrap/Button";
// import UiModal from "./components/bootstrap/Modal";
// import UiButtonGroup from "./components/bootstrap/ButtonGroup";
// import UiListGroup from "./components/bootstrap/ListGroup";
// import UiMediaObject from "./components/bootstrap/MediaObject";
// import UiCards from "./components/bootstrap/Cards";
// import UiCarousel from "./components/bootstrap/Carousel";
// import UiDropDown from "./components/bootstrap/DropDown";
// import UiPopOver from "./components/bootstrap/PopOver";
// import UiProgressBar from "./components/bootstrap/ProgressBar";
// import UiTab from "./components/bootstrap/Tab";
// import UiPagination from "./components/bootstrap/Pagination";
// import UiGrid from "./components/bootstrap/Grid";
// import UiTypography from "./components/bootstrap/Typography";

/// App
// import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
// import Compose from "./components/AppsMenu/Email/Compose/Compose";
// import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
// import Read from "./components/AppsMenu/Email/Read/Read";
// import Calendar from "./components/AppsMenu/Calendar/Calendar";
// import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
// import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
// import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
// import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
// import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
// import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
// import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
// import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Chirt
// import SparklineChart from "./components/charts/Sparkline";
// import ChartJs from "./components/charts/Chartjs";
// import Chartist from "./components/charts/chartist";

// import BtcChart from "./components/charts/apexcharts/ApexChart";

/// Table
// import DataTable from "./components/table/DataTable";
// import BootstrapTable from "./components/table/BootstrapTable";
// import ApexChart from "./components/charts/apexcharts";

/// Form
// import Element from "./components/Forms/Element/Element";
// import Wizard from "./components/Forms/Wizard/Wizard";
// import SummerNote from "./components/Forms/Summernote/SummerNote";
// import Pickers from "./components/Forms/Pickers/Pickers";
// import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pulgin
// import Select2 from "./components/PluginsMenu/Select2/Select2";
// import Nestable from "./components/PluginsMenu/Nestable/Nestable";
// import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
// import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
// import Toastr from "./components/PluginsMenu/Toastr/Toastr";
// import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
// import RechartJs from "./components/charts/rechart";
import ProfileSidebar from "./layouts/ProfileSidebar";
// import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

const Markup = ({ showProfileSideBar }) => {

   const [showProfile, setShowProfile] = useState(false);

   let path = window.location.pathname;
   path = path.split("/");
   path = path[path.length - 1];
   let pagePath = path.split("-").includes("page");

   const isAuthenticated = () => {
      const token = localStorage.getItem('token')
      let isValid = true
      try {
          isValid = decode(token);
      } catch (e) {
          return false;
      }
      return isValid;
    
    };

   // const MyRoute = (props) => (
   //    isAuthenticated()
   //          ? <Route
   //          path={`/tematicas`}
   //          component={TematicaS} />
   //          : <Route exact component={Login} key={5} />
   // )

   const MyRoute = (props) => (
      isAuthenticated()
          ? <Route {...props} />
          : <Route render={() => <Redirect to="/" />}/>
    )

   return (
      // <BrowserRouter>
      //    <Switch>
      //       <Route exact path={'/'} component={Login} />
      //       <MyRoute path={'/tematicas'} component={TematicaS} />
      //    </Switch>
      // </BrowserRouter>
      <BrowserRouter>
      <div
      id={`${!pagePath ? "main-wrapper" : ""}`}
      className={`${!pagePath ? "show" : "mh100vh"} ${
         !path ? "right-profile" : ""
      }`}
   >
      {!pagePath && (
         <Nav
            showProfileSideBar={showProfileSideBar}
            showProfile={() => setShowProfile(true)}
         />
      )}
      {/* {!path && !pagePath && (
         <ProfileSidebar
            addClass={showProfile ? "active" : ""}
            hideProfile={() => setShowProfile(false)}
         />
      )} */}
      <div
         className={`  ${!pagePath ? "content-body" : ""}`}
        
      >
         <div className={`${!pagePath ? "container-fluid" : ""}`}>
         <Switch>
            <Route exact path={'/'} component={Login} />
            <MyRoute path={'/tematicas'} component={TematicaS} />
         </Switch>
         </div>
      </div>
      
   </div>
   </BrowserRouter>
   );
};

export default Markup;
