import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";

import ChartDoughnut2 from "../charts/Chartjs/donught2";
import dbuton from "../../../images/dbuton.png";

function Campaign() {
   const hoy = new Date();
   const fecha = hoy.getDate() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getFullYear();
   var hora = hoy.getHours() + ':' + hoy.getMinutes();
   return (
      <>
         <div className="form-head d-flex mb-0 mb-lg-4 align-items-start">
            <div className="mr-auto d-none d-lg-block">
               <h2 className="text-black font-w600">Tematica</h2>
               <p className="mb-0">Semestral</p>
            </div>
            <div className="d-none d-lg-flex align-items-center">
               <div className="text-right">
                  <h3 className="fs-20 text-black mb-0">{JSON.stringify(hora)}</h3>
                  <span className="fs-14">{JSON.stringify(fecha)}</span>
               </div>
               {/* <Link
                  className="ml-4 text-black p-3 rounded border text-center width60"
                  to="/campaign"
               >
                  <i className="las la-cog scale5" />
               </Link> */}
            </div>
         </div>
         <div className="row">
            <div className="col-xl-12">
               <div className="d-lg-flex d-block pl-0 pr-0 border-0 align-items-end justify-content-between mb-3">
                  <div className>
                     {/* <Link to="/campaign" className="btn bg-white mb-2 mr-2">
                        <svg
                           className="mr-2 scale5"
                           width={14}
                           height={14}
                           viewBox="0 0 18 24"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M17.9375 4.6875V22.5625C17.9378 22.7117 17.897 22.8581 17.8196 22.9857C17.7422 23.1132 17.6312 23.217 17.4987 23.2856C17.3831 23.3449 17.2549 23.3755 17.125 23.375C16.9593 23.3746 16.7977 23.3236 16.6619 23.2288L9 17.8662L1.33813 23.2288C1.21968 23.3089 1.08218 23.3564 0.939516 23.3664C0.796853 23.3763 0.65408 23.3485 0.525625 23.2856C0.388595 23.2204 0.272581 23.118 0.190727 22.9902C0.108873 22.8624 0.064453 22.7143 0.0625 22.5625V4.6875C0.0625 3.61006 0.490512 2.57675 1.25238 1.81488C2.01425 1.05301 3.04756 0.625 4.125 0.625H13.875C14.9524 0.625 15.9858 1.05301 16.7476 1.81488C17.5095 2.57675 17.9375 3.61006 17.9375 4.6875Z"
                              fill="#353751"
                           />
                        </svg>
                        <span className="text-black">Boookmark</span>
                     </Link>
                     <Link to="/campaign" className="btn bg-white mb-2 mr-2">
                        <svg
                           className="mr-2 scale5"
                           width={14}
                           height={14}
                           viewBox="0 0 23 22"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M9.72803 10.4999L9.72796 3.1005C9.72795 1.31868 11.8822 0.426337 13.1422 1.68627L21.0416 9.58573C21.8227 10.3668 21.8227 11.6331 21.0416 12.4142L13.1422 20.3136C11.8822 21.5735 9.72795 20.6812 9.72796 18.8994L9.72803 10.4999Z"
                              fill="#52B141"
                           />
                        </svg>
                        <span className="text-black">Start</span>
                     </Link> */}
                     {/* <Link to="/campaign" className="btn bg-white mb-2 mr-2">
                        <svg
                           className="mr-2 scale5 scale5"
                           width={14}
                           height={14}
                           viewBox="0 0 12 18"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M8 1C8 0.447716 8.44772 0 9 0H11C11.5523 0 12 0.447715 12 1V17C12 17.5523 11.5523 18 11 18H9C8.44772 18 8 17.5523 8 17V1Z"
                              fill="#8E8E8E"
                           />
                           <path
                              d="M0 1C0 0.447716 0.447715 0 1 0H3C3.55228 0 4 0.447715 4 1V17C4 17.5523 3.55228 18 3 18H1C0.447715 18 0 17.5523 0 17V1Z"
                              fill="#8E8E8E"
                           />
                        </svg>
                        <span className="text-black">Pause</span>
                     </Link> */}
                     {/* <Link to="/campaign" className="btn bg-white mb-2 mr-2">
                        <svg
                           className="mr-2 scale5"
                           width={14}
                           height={14}
                           viewBox="0 0 18 18"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <rect width={14} height={14} rx={2} fill="#F83737" />
                        </svg>
                        <span className="text-black">Stop</span>
                     </Link> */}
                  </div>
                  <div>
                     {/* <Link to="/campaign" className="btn bg-white mb-2 mr-2">
                        <svg
                           width={14}
                           height={14}
                           className="scale5"
                           viewBox="0 0 20 18"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M19 2.25H8.5C8.5 1.42275 7.82725 0.75 7 0.75H5.5C4.67275 0.75 4 1.42275 4 2.25H1C0.586 2.25 0.25 2.58525 0.25 3C0.25 3.41475 0.586 3.75 1 3.75H4C4 4.57725 4.67275 5.25 5.5 5.25H7C7.82725 5.25 8.5 4.57725 8.5 3.75H19C19.414 3.75 19.75 3.41475 19.75 3C19.75 2.58525 19.414 2.25 19 2.25ZM5.5 3.75V2.25H7L7.00075 2.997C7.00075 2.9985 7 2.99925 7 3C7 3.00075 7.00075 3.0015 7.00075 3.003V3.75H5.5Z"
                              fill="#2E2E2E"
                           />
                           <path
                              d="M19 8.25H14.5C14.5 7.42275 13.8273 6.75 13 6.75H11.5C10.6727 6.75 10 7.42275 10 8.25H1C0.586 8.25 0.25 8.58525 0.25 9C0.25 9.41475 0.586 9.75 1 9.75H10C10 10.5773 10.6727 11.25 11.5 11.25H13C13.8273 11.25 14.5 10.5773 14.5 9.75H19C19.414 9.75 19.75 9.41475 19.75 9C19.75 8.58525 19.414 8.25 19 8.25ZM11.5 9.75V8.25H13L13.0007 8.997C13.0007 8.9985 13 8.99925 13 9C13 9.00075 13.0007 9.0015 13.0007 9.003V9.75H11.5Z"
                              fill="#2E2E2E"
                           />
                           <path
                              d="M19 14.25H8.5C8.5 13.4227 7.82725 12.75 7 12.75H5.5C4.67275 12.75 4 13.4227 4 14.25H1C0.586 14.25 0.25 14.5853 0.25 15C0.25 15.4148 0.586 15.75 1 15.75H4C4 16.5773 4.67275 17.25 5.5 17.25H7C7.82725 17.25 8.5 16.5773 8.5 15.75H19C19.414 15.75 19.75 15.4148 19.75 15C19.75 14.5853 19.414 14.25 19 14.25ZM5.5 15.75V14.25H7L7.00075 14.997C7.00075 14.9985 7 14.9992 7 15C7 15.0008 7.00075 15.0015 7.00075 15.003V15.75H5.5Z"
                              fill="#2E2E2E"
                           />
                        </svg>
                     </Link> */}
                     {/* <Dropdown className="custom-dropdown mb-2">
                        <Dropdown.Toggle
                           variant=""
                           className="arrow-false btn bg-white d-flex align-items-center"
                        >
                           <div className="text-left mr-3">
                              <span className="text-black">Newest</span>
                           </div>
                           <i className="fa fa-caret-down" aria-hidden="true" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right">
                           <Dropdown.Item className="" to="/campaign">
                              4 June 2020 - 4 July 2020
                           </Dropdown.Item>
                           <Dropdown.Item className="" to="/campaign">
                              5 july 2020 - 4 Aug 2020
                           </Dropdown.Item>
                        </Dropdown.Menu>
                     </Dropdown> */}
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="100"
                           />
                           <small>AV</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">Audiovisual - TEMA: IDEAS MENTIROSAS</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 AUDIOVISUAL
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: IDEAS MENTIROSAS
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://drive.google.com/drive/folders/134txoVBu4DKF6MBEnp_CBE4xm8WP2uXB?usp=sharing"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="5.8"
                           />
                           <small>01</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">10 Agosto 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #1
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                                 href="www.google.com"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - Tema: Fracaso: tu pasado te arruinó para siempre
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1YyPk4ZFDdqQEfpgu2dCYGQ0t2xaPMAk7"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="11.6"
                           />
                           <small>02</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">17 Agosto 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #2
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - Tema: Exito: las cosas buenas que hacen te hacen bueno
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1YgK0pQq78dxzkUg0eoc0WM6v7mky8FxG"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="17.4"
                           />
                           <small>03</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">24 Agosto 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #3
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - Tema: Optimismo: si piensas positivamente todo va a salir bien
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1aFkXSYaZK370Yw_W20_cg0E6lf0Vea95"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="23.2"
                           />
                           <small>04</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">31 Agosto 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #4
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - Tema: Control: solo tú decides qué hacer con tu vida
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1v5pNH54e0e-HacXZG22a48zFBamBneu8"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="29.4"
                           />
                           <small>05</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">7 Septiembre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #5
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - Tema: Invitado: es bueno involucrar a Dios en tu vida
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1vFm-LZgcl7a1uYrjlHAHwa5UKnaCbpFw"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="35.6"
                           />
                           <small>06</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">14 Septiembre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #6
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - Tema: ¡Gózate! Disfruta el momento
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1mSqai-gyhXBI7k5ms8sE4lXX6cwx4L5r"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="41.8"
                           />
                           <small>07</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">21 Septiembre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #7
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - Tema: Distante: Dios está lejos y muy ocupado, no le interesas
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1sPsfvay-ZCDLtsM44HgI7imDeahS9RCU"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="48"
                           />
                           <small>08</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">28 Septiembre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #8
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - Tema: Es lo mismo: al final, todos adoramos a un mismo Dios
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1oSXKcUi5SEQOPMlJZTLUyx4Ozc2GiT1u"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="100"
                           />
                           <small>AV</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">AUDIOVISUAL - TEMA: PREGUNTAS JUVENILES</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 AUDIOVISUAL
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: PREGUNTAS JUVENILES
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://drive.google.com/drive/folders/1lQ4mRzrn1W7uBelP9DBlaSMkXT_yiDAz?usp=sharing"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="54.2"
                           />
                           <small>01</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">5 Octubre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #1
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: ¿Cumple siempre Dios sus promesas?
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1I9VoTwzS1ksQlfPPZRaw9R6pgjsHnZch"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="60.4"
                           />
                           <small>02</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">12 Octubre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #2
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: ¿Qué hago si pienso que ya no le importo a Dios?
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1FM2HRlHecY7toUT3KG5UEt8zuwNDazSW"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="66.6"
                           />
                           <small>03</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">19 Octubre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #3
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: ¿Dónde está Dios cuando soy tentado a pecar?
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1RNqctO-LoWDLusXeckJ4kYHxbH0qYBO7"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="72.8"
                           />
                           <small>04</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">26 Octubre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #4
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: ¿Me perdona Dios si peco a propósito?
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1rnUFG70WFe1vZ0RjqltutNsU3x07dFWi"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="79"
                           />
                           <small>05</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">2 Noviembre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #5
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: ¿Porqué permite Dios que suframos?
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1ST6KrQaigBBOvClyxUI7M-_NXWfPh9Uu"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="85.2"
                           />
                           <small>06</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">9 Noviembre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #6
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: ¿Qué hago si siento que no puedo perdonar a alguien?
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1bt0bMal9vp2XIL_CtL4U-WeprlDghQ_m"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="91.4"
                           />
                           <small>07</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">16 Noviembre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #7
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: ¿Puedo yo servir a Dios así como soy?
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=1lpHti0gOMQIjJ_CYEstlDtBuAUP6zewh"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
            <div className="campaign-audio col-md-12">
               <div className="compaign-row align-items-center p-sm-4 p-3 row sp16 mx-0 mb-2">
                  <div className="my-2 col-xl-4 col-xxl-6 col-lg-6 col-md-8 col-sm-12">
                     <div className="media align-items-center">
                        <div className="d-inline-block position-relative donut-chart-sale mr-4">
                           <ChartDoughnut2
                              backgroundColor="#d40312"
                              height="90"
                              width="90"
                              value="100"
                           />
                           <small>08</small>
                        </div>
                        <div className="media-body">
                           <p className="text-primary mb-1">23 Noviembre 2024</p>
                           <h3 className="fs-20">
                              <Link className="text-black">
                                 Lección #8
                              </Link>
                           </h3>
                           <svg
                              width={17}
                              height={14}
                              viewBox="0 0 17 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                           >
                              <path
                                 d="M15 2L5 12.01L2 9.01"
                                 stroke="#737373"
                                 strokeWidth={3}
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                              />
                           </svg>
                           <span className="fs-14 ml-1">
                              Segundo Semestre Juventud Viva - TEMA: ¿Cómo puedo ser feliz en esta vida?
                           </span>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-2 col-xxl-6 col-lg-6 col-md-4 col-sm-8 px-3">
                  </div>
                  <div className="col-xl-2 col-xxl-4 col-lg-4 col-md-6 text-center my-2 col-sm-4">
                  </div>
                  <div className="col-xl-4 col-xxl-8 col-lg-8 col-md-6 text-left text-md-right my-2">
                     
                     <a
                        href="https://docs.google.com/uc?export=download&id=18EfrfkPjdPrvAobDW9aMUzB4ywu4CaVo"
                        className="border border-dark audio-btn ml-2 mb-2"
                     >
                        <img src={dbuton} style={{width: '40%'}} alt="" />
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Campaign;
